import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vip_discount: 0.95,
    userAddressData: {}
  },
  mutations: {
  },
  actions: {
    isWXBrowser() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    }
  },
  modules: {
  }
})
